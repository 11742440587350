<template>
  <!-- 智慧城市 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">排污检测</div>
      <div class="title-p">
        建立一套数据驱动的标准化的管理模式，安全运行体系，排水信息化支撑平台和设施规划体系。
      </div>
    </div>
    <div class="function">
      <div class="scenes-box">
        <h3>方案优势</h3>

        <div class="scenes-bg">
          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/monitor/glkf.png"
                alt=""
              />
              管理规范
            </div>
            <div class="targetContent">
              通过广泛、精确且实时的智能化监测手段，做到用数据说话、
              用数据决策、用数据管理、用数据创新”，旨在逐步建立以数据为驱动的标准化管理模式，
              并建立相应的管理支撑平台。
            </div>
          </div>

          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/monitor/yxaq.png"
                alt=""
              />
              运行安全
            </div>
            <div class="targetContent">
              对排水资产进行全面管理，建立资产设备维修台账、检测台账，通过智能监测手段，结合大数据分析对设施资产进行定期评估，预测运行风险，实现排水设施资产全生命周期管理。
            </div>
          </div>
          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/monitor/gxjc.png"
                alt=""
              />
              高效决策
            </div>
            <div class="targetContent">
              通过建立一套涵盖“源-网-厂-
              河”监测预警体系，为排水信息化支撑平台提供更为精准的数据支撑，使得城市管理的思路、方略和决策更为科学民主，从而加快排水事业科学发展进程。
            </div>
          </div>
          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/monitor/khzq.png"
                alt=""
              />
              规划准确
            </div>
            <div class="targetContent">
              通过选择关键点位，对现有的排水系统进行系统记录排查和实时监测，真实了解管网及设施的现状运行情况，判断是否需要提升改造，为管理部门
              和相关单位提供必要的信息和决策依据
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <h3>平台架构</h3>
      <div class="services">
        <div class="left">
          <img
            style="width: 100%; height: 100%"
            @click="onYuLan(img)"
            :src="img"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="function">
      <h3>功能场景</h3>
    </div>
    <div class="scene">
      <div class="img1">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">排污检测</div>
          <div class="sceneContents">
            确保治理设施稳定运行，监控排放水质达标且不超量排放，监测偷排漏排
          </div>
        </div>
      </div>

      <div class="img2">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">管网监测</div>
          <div class="sceneContents">
            管网堵塞、破损告警，分析降雨引起的入流入渗量和区域偷排漏排的情况
          </div>
        </div>
      </div>
      <div class="img3">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">河湖监测</div>
          <div class="sceneContents">
            实时监测水质ORP、电导率、氨氮等指标； 分析水质变化跟踪与预警；
            网格化监测管理
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return { img: require("../../assets/image/monitor/paishui.png") };
  },
  computed: {},
  watch: {},
  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
h3 {
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 5px;
}
.one {
  color: #b0b7bf;
}
.content-box {
  width: 100%;
  overflow: hidden;
  background-color: #fff;

  .title-box {
    padding-top: 40px;
    width: 100%;
    color: #fff;
    height: 281px;

    background: url("../../assets/image/monitor/mpwjc.png") no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      transform: translateY(30px);
      text-align: center;
      letter-spacing: 2px;
    }
    .title-p {
      padding: 0 40px;
      font-size: 16px;

      transform: translateY(45px);
      color: #ffffff;
      text-align: left;
      letter-spacing: 2px;
      line-height: 28px;
    }
  }

  .function {
    background-color: #fff;

    .scenes-box {
      padding-top: 20px;
      background-color: #fff;

      .scenes-bg {
        padding: 0 10px;

        // height: 170px;

        > div {
          width: 100%;
          //   height: 100%;
          height: 200px;
          background: url("../../assets/image/wisdomWater/mbbj.png") no-repeat;
          background-size: 100% 100%;
          margin: 15px 0;
          .leftTop {
            width: 38%;
            height: 40px;
            line-height: 40px;
            text-align: left;
            color: #fff;
            border-bottom-right-radius: 25px;

            background-color: #c7b090;
            padding: 5px 0;
            font-weight: bold;
          }
          .targetContent {
            // margin: 10px;
            color: #6b6b6b;
            line-height: 30px;
            font-size: 15px;
            padding: 15px;
          }
        }
        .icons {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-left: 20px;
        }
        .iconss {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-bottom: 3px;
          margin-left: 20px;
        }
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      padding: 0 10px;
      padding-bottom: 20px;
      background-color: #fff;
      .left {
        width: 100%;
        height: 400px;
        margin-top: 4%;
        background: url("../../assets/image/monitor/paishui.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
}

.scene {
  padding: 0 10px;
  margin-bottom: 20px;
  //   overflow: hidden;
  .sceneBox {
    position: relative;
    margin: 5px 0;

    .sceneContents {
      padding: 5px 30px;
      color: white;
      font-size: 14px;
      line-height: 30px;
      text-align: left;
    }

    .sceneIcon {
      width: 44px;
      height: 44px;
    }
    .sceneTitle {
      position: relative;
      padding-top: 80px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;

      box-sizing: border-box;
      padding-bottom: 10px;
      text-align: center;
      //   border-bottom: 2px solid #fff;
      &::before {
        position: absolute;
        content: "";
        width: 120px;
        height: 1px;
        background-color: #fff;
        bottom: 1%;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
    }
  }
}

.img1 {
  height: 220px;
  background: url("../../assets/image/monitor/pwbg.png") no-repeat;
  background-size: 100% 100%;
  // margin: 0 5%;
}
.img2 {
  height: 220px;
  background: url("../../assets/image/monitor/gwbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}
.img3 {
  height: 220px;
  background: url("../../assets/image/monitor/hhbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}

.icon1 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: url("../../assets/image/monitor/pwIcon.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: url("../../assets/image/monitor/gwIcon.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: url("../../assets/image/monitor/hehuIcon.png") no-repeat;
  background-size: 100% 100%;
}
</style>
